@import '/styles/helpers/responsive.scss';

.page {
  background-color: var(--color-bg);
  padding: 20px 20px 50px;
  min-height: 600px;
  text-align: center;

  @include mobile {
    min-height: initial;
    padding: 40px 20px;
  }
}